import { render, staticRenderFns } from "./ReservationList.vue?vue&type=template&id=1f9ed871&scoped=true"
import script from "./ReservationList.vue?vue&type=script&lang=js"
export * from "./ReservationList.vue?vue&type=script&lang=js"
import style0 from "./ReservationList.vue?vue&type=style&index=0&id=1f9ed871&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f9ed871",
  null
  
)

export default component.exports