<template>
  <div class="reservations-list">
    <PageTitleExtra
      title="跨境訪客預約人次"
      btn="匯出"
      btnFeature="admin.appointmentOrder.create"
      cyBtn1="new-reservation-btn"
      tooltip="每一筆Email預約訂單計算為1次，預約人數大於1也計算為1次。"
      tooltipWidth="auto"
      @btnClick="showExportOptions = true"
    />
    <div v-loading="countLoading" class="monthlyData grid gap-[12px]">
      <div class="flex justify-between relative">
        <p class="text-lg font-medium">{{ currentMonth }}月訪客預約人次： {{ monthRecordCount }} 次</p>
        <el-date-picker
          v-model="search.date"
          editable
          class="selectMonth"
          type="month"
          placeholder="請選擇月份"
          @change="refreshCount(true)"
        />
      </div>
      <p class="text-sub text-gray-60">歷史訪客預約人次總數：{{ reservationsCount }} / {{ reservationslimit }}(合約上限)</p>
    </div>
    <FiltersContainer>
      <BaseElInput
        v-model="search.visitorName"
        clearable
        placeholder="請輸入訪客姓名"
        class="flex-grow-1"
        @keypress.enter.native="refresh(true)"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>
      <BaseElInput
        v-model="search.visitorEmail"
        clearable
        placeholder="請輸入訪客Email"
        class="flex-grow-1"
        @keyup.enter.native="refresh(true)"
        @clear="refresh"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>
      <BaseElSelect v-model="search.hasVisitorAuthGoogle" placeholder="請選擇登入方式" clearable @change="refresh(true)">
        <BaseElSelectOption
          v-for="option in memberLoginTypeConfig"
          :key="option.value"
          :value="option.value"
          :label="option.label"
        />
      </BaseElSelect>
      <BaseElSelect v-model="search.regionCode" placeholder="請選擇所在地區" clearable @change="refresh(true)">
        <BaseElSelectOption
          v-for="option in regionCodeList"
          :key="option.regionCode"
          :value="option.regionCode"
          :label="option.regionNameCh"
        />
      </BaseElSelect>
    </FiltersContainer>
    <section>
      <BaseTable v-loading="loading" :data="displayData" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="visitorName" label="姓名" align="center" />
        <BaseElTableColumn prop="visitorEmail" label="Email" align="center" />
        <BaseElTableColumn
          prop="visitorAuth"
          label="登入方式"
          align="center"
        >
          <template slot-scope="scope">
            <div class="login-auth">
              <img v-if="scope.row.visitorAuth" src="@/assets/member/icon-auth-google.svg" alt="Google auth icon">
              <img v-else src="@/assets/member/icon-auth-email.svg" alt="email auth icon">
            </div>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="regionNameCh" label="所在地區" align="center" />
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="reservationsCount"
        @pageChange="refresh"
      />
    </section>

    <ExportOptionsDialog
      v-if="showExportOptions"
      @close="showExportOptions = false"
      @export="prepareExport"
    />

    <ExportDialog
      v-if="exportState.modal"
      title="匯出跨境人次紀錄"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :content="exportState.content"
      :percentage="totalPercentege"
      @close="resetExport"
    />
  </div>
</template>

<script>
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import PageTitleExtra from '@/components/Title/PageTitleExtra.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import dayjs from '@/lib/dayjs'
// Utils
import { pageStartIndex } from '@/utils/table'
import {
  getMonth,
} from '@/utils/date'
import { ExportExcel } from '@/utils/excel'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'
import { useExport } from '@/use/export'
import { useShop } from '@/use/shop'
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import { memberLoginTypeConfig } from '@/config/member'
import { GetPubAptReservation, GetPubAptReservationCount, GetRegionCodeList } from '@/api/pubApt'

export default defineComponent({
  name: 'ReservationsList',
  components: {
    EmptyBlock,
    PageTitleExtra,
    ExportDialog,
    ExportOptionsDialog,
  },
  setup () {
    const { exportState, resetExport, getExportDataOld, totalPercentege } = useExport()
    const { tableOptions } = useTable()
    const { shopId } = useShop()
    const showExportOptions = ref(false)
    const loading = ref(false)
    const countLoading = ref(false)
    const showInfo = ref(false)
    const search = reactive({
      visitorName: null,
      visitorEmail: null,
      hasVisitorAuthGoogle: null,
      regionCode: null,
      date: '',
    })
    const reservationsList = ref([])
    const reservationsCount = ref(0)
    const reservationslimit = ref(3000)
    const monthRecordCount = ref(0)
    const currentMonth = ref(1)
    const regionCodeList = ref([])

    const formatExportData = (exportData) => {
      const data = []
      exportData.forEach((item) => {
        const row = {
          姓: get(item, 'profile.lastName', '-'),
          名: get(item, 'profile.firstName', '-'),
          Email: get(item, 'Visitor.email', '-'),
          登入方式: get(item, 'Visitor.VisitorAuthGoogle.id') ? 'Google 信箱' : '電子郵件驗證',
          所在地區: get(item, 'deviceInfo.regionNameCh', '-'),
        }

        data.push(row)
      })
      return data
    }

    const prepareExport = async ({ all, range }) => {
      showExportOptions.value = false
      exportState.modal = true
      exportState.exportting = true

      const payload = {
        shopId: shopId.value,
        start: pageStartIndex(tableOptions.page, tableOptions.pageLimit),
        limit: tableOptions.pageLimit,
        visitorName: search.visitorName ? search.visitorName.trim() : undefined,
        visitorEmail: search.visitorEmail ? search.visitorEmail.trim() : undefined,
        hasVisitorAuthGoogle: search.hasVisitorAuthGoogle || undefined,
        regionCode: search.regionCode ? search.regionCode : undefined,
        orderDateStart: all ? undefined : range[0],
        orderDateEnd: all ? undefined : range[1],
      }

      try {
        const [resCount, errCount] = await GetPubAptReservationCount(payload)
        if (errCount) {
          window.$message.error(errCount.message || errCount)
          exportState.exportting = false
          exportState.error = true
          return
        }
        exportState.dataCount = resCount.count
        if (!exportState.dataCount) {
          exportState.content = '尚無資料可匯出'
          exportState.error = true
          window.$message.warning('尚無資料可匯出')
          return
        }

        const [resData, err] = await getExportDataOld({ stage: 0, fetchAPI: GetPubAptReservation, payload })
        if (err) {
          window.$message.error(err.message || err)
          exportState.exportting = false
          exportState.error = true
          return
        }
        const formatData = formatExportData(resData)
        exportState.success = true
        exportState.content = '匯出完成'
        ExportExcel(formatData, '跨境人次紀錄', '跨境人次紀錄')
      } catch (error) {
        window.$message.error(error.message || error)
        console.log(error.message || error)
        exportState.exportting = false
        exportState.error = true
      }
    }
    const getRegionCodeList = async () => {
      const [res, err] = await GetRegionCodeList({ shopId: shopId.value })
      if (err) {
        window.$message.error(err.message || err)
        return
      }
      regionCodeList.value = res.regionCodeList
    }
    const getReservation = async () => {
      const now = dayjs(new Date())
      // const year = search.date ? dayjs(search.date).format('YYYY') : now.format('YYYY')
      // const month = search.date ? dayjs(search.date).format('M') : now.format('MM')
      // const {
      //   start,
      //   end,
      // } = getMonth(year, month)
      const [res, err] = await GetPubAptReservation({
        shopId: shopId.value,
        start: pageStartIndex(tableOptions.page, tableOptions.pageLimit),
        limit: tableOptions.pageLimit,
        visitorName: search.visitorName ? search.visitorName.trim() : undefined,
        visitorEmail: search.visitorEmail ? search.visitorEmail.trim() : undefined,
        hasVisitorAuthGoogle: search.hasVisitorAuthGoogle || undefined,
        regionCode: search.regionCode ? search.regionCode : undefined,
        // createdAtStart: start,
        // createdAtEnd: end,
      })
      if (err) {
        window.$message.error(err.message || err)
        return
      }
      reservationsList.value = res
    }
    const displayData = computed(() => {
      return map(reservationsList.value, item => {
        return {
          id: get(item, 'id', '-'),
          AppointmentOrderId: get(item, 'AppointmentOrderId', '-'),
          visitorName: get(item, 'Visitor.name') || `${get(item, 'profile.firstName')} ${get(item, 'profile.lastName')}` || '-',
          visitorEmail: get(item, 'Visitor.email', '-'),
          regionCode: get(item, 'deviceInfo.regionCode', '-'),
          regionNameCh: get(item, 'deviceInfo.regionNameCh', '-'),
          visitorAuth: get(item, 'Visitor.VisitorAuthGoogle.id', null),
        }
      })
    })
    const getReservationCount = async () => {
      const [res, err] = await GetPubAptReservationCount({
        shopId: shopId.value,
        visitorName: search.visitorName ? search.visitorName.trim() : undefined,
        visitorEmail: search.visitorEmail ? search.visitorEmail.trim() : undefined,
        hasVisitorAuthGoogle: search.hasVisitorAuthGoogle || undefined,
        regionCode: search.regionCode ? search.regionCode : undefined,
        // createdAtStart: search.date ? search.date[0] : undefined,
        // createdAtEnd: search.date ? search.date[1] : undefined,
      })
      if (err) {
        window.$message.error(err.message || err)
        return
      }
      reservationsCount.value = res.count || 0
    }
    const getMonthReservation = async () => {
      const now = dayjs(new Date())
      const year = search.date ? dayjs(search.date).format('YYYY') : now.format('YYYY')
      const month = search.date ? dayjs(search.date).format('M') : now.format('MM')
      const {
        start,
        end,
      } = getMonth(year, month)
      const [res, err] = await GetPubAptReservationCount({
        shopId: shopId.value,
        visitorName: search.visitorName ? search.visitorName.trim() : undefined,
        visitorEmail: search.visitorEmail ? search.visitorEmail.trim() : undefined,
        hasVisitorAuthGoogle: search.hasVisitorAuthGoogle || undefined,
        regionCode: search.regionCode ? search.regionCode : undefined,
        orderDateStart: start,
        orderDateEnd: end,
      })
      if (err) return window.$message.error(err)
      monthRecordCount.value = res.count || 0
    }
    const refreshCount = async () => {
      countLoading.value = true
      currentMonth.value = search.date ? dayjs(search.date).format('M') : new Date().getMonth() + 1
      await Promise.all([
        getReservationCount(),
        getMonthReservation(),
      ])
      countLoading.value = false
    }
    const refresh = async (force = false) => {
      loading.value = true
      if (force === true) tableOptions.page = 1
      await Promise.all([
        getRegionCodeList(),
        getReservation(),
        // getReservationCount(),
        // getMonthReservation(),
      ])
      loading.value = false
    }
    onMounted(async () => {
      await refresh()
      await refreshCount()
    })
    return {
      exportState,
      prepareExport,
      search,
      resetExport,
      totalPercentege,
      showExportOptions,
      memberLoginTypeConfig,
      regionCodeList,
      refresh,
      refreshCount,
      loading,
      countLoading,
      reservationsList,
      reservationsCount,
      reservationslimit,
      monthRecordCount,
      tableOptions,
      displayData,
      currentMonth,
      showInfo,
    }
  },
})
</script>

<style scoped lang="postcss">
.monthlyData {
  @apply max-w-[692px] bg-white p-[20px] rounded-[4px] mb-[20px];
  box-shadow: 0 1px 13px 0 #0000001A;
}
.selectMonth {
  @apply w-[134px]
}
.login-auth {
  @apply flex justify-center gap-[4px];
}
.reservations-list {
  height: 100%;
}
</style>
